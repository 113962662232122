<template>
  <!--<a :href="fileUrl" :target="target"> -->
  <a href="javascript:void(0)">
    <canvas :id="_uid" style="border:1px solid #DEE2E6"/>
  </a>
</template>

<script>
import {mapMutations} from 'vuex';
import * as pdfjsLib from 'pdfjs-dist/es5/build/pdf.js'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`
export default {
  name: 'PdfThumbnail',
  props: {
    fileUrl: {
      type: String,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    target:{
        type: String,
        default:"_blank",
    },
    updateFile:{
      type: String,
    },
  },
  mounted() {
    this.renderThumbnails();
  },
  methods: {
    ...mapMutations(['setLoading']),
    async renderThumbnails() {
      this.setLoading(true);
      const worker = new pdfjsLib.PDFWorker()
      let pdf = await pdfjsLib
        .getDocument({ url: this.fileUrl, worker: worker })
        .promise.then((pdf) => pdf)
      const page = await pdf.getPage(1)
      let viewport = page.getViewport({ scale: 1.0 })
      viewport = page.getViewport({ scale: this.width / viewport.width })
      let canvas = document.getElementById(this._uid)
      //canvas.height = viewport.height
      //canvas.width = viewport.width
      canvas.height = this.height
      canvas.width = this.width
      const context = canvas.getContext('2d');
      await page.render({ canvasContext: context, viewport: viewport });
      
      setTimeout(()=>{
        this.setLoading(false);
      }, 2000);
    },
  },
  watch:{
    updateFile(value){      
      this.renderThumbnails();
    },
  },
}
</script>

<style>
</style>