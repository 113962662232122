import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: Dashboard
  },
  {
    path: '/download/:codigo_unico',
    name: 'Download',
    component: () => import('../views/Descarga.vue')
  },
  {
    path: "*",
    name: "NotFound",
    property: {},
    children: [],
   component: () => import('../views/Error404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
