import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import mixins from './mixins';
import axios from 'axios';

Vue.config.productionTip = false
window.baseUrl  = process.env.VUE_APP_ROOT_API;
window.tokenAPI = process.env.VUE_APP_TOKEN_API;//Para mi token API
window.baseCli  = process.env.VUE_APP_ROOT_CLI;

const instance  = axios.create({
  baseURL: window.baseUrl,
  headers: {
    Authorization: "Bearer "+window.tokenAPI
  }
});

Vue.mixin(mixins);
Vue.prototype.$http=instance;
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
