<template>
    <div>
        <footer id="footer">
            <div :class="['footer-top']">
                <div :class="['container']">
                    <div :class="['row']">
                        <div :class="['col-lg-5', 'col-md-6', 'footer-links']">
                            <router-link :to="'/'">
                                <img :src="empresa.path_file_logo" style="width:40%;"/>
                            </router-link>
                            <h4>{{empresa.abreviatura}}</h4>
                            <div v-for="(val, key) in empresa.sucursales" :key="key">
                                <h4 style="padding-bottom: 0px">{{val.sucursal}}</h4>
                                <i :class="['fa', 'fa-map-marker']" aria-hidden="true"></i> {{val.direccion}} <br>
                                <i :class="['fa', 'fa-clock-o']" aria-hidden="true"></i> {{val.horario_atencion}} <br>
                                <strong><i :class="['fa', 'fa-phone']" aria-hidden="true"></i> Tel&eacute;fono:</strong> {{val.telefono}}<br>
                                <hr style="border-top: 1px solid #428BCA; margin-top: 0.5rem;">
                            </div>
                            <p>
                                <strong><i :class="['fa', 'fa-envelope']" aria-hidden="true"></i> Email:</strong> {{empresa.email}}<br>
                                <strong><i :class="['fa', 'fa-building']" aria-hidden="true"></i> RUC:</strong> {{empresa.ruc}}<br>
                            </p>
                        </div>

                        <div :class="['col-lg-3', 'col-md-6', 'footer-links']">
                            <h4>Sistemas Administrativos</h4>
                            <ul>
                                <li><a href="https://apps.regionsanmartin.gob.pe:6443/SIRSAM/" target="_blank"><i :class="['fa', 'fa-window-maximize']"></i> Sistema Integrado Regional (SIRSAM)</a></li>
                                <li><a href="http://sigaweb.regionsanmartin.gob.pe:8080/SIGAWEB/" target="_blank"><i :class="['fa', 'fa-window-maximize']"></i> Sistema Integrado de Gestion Administrativa (SIGA WEB)</a></li>
                                <li><a href="https://www.regionsanmartin.gob.pe/Servicios?url=consulta_tramite" target="_blank"><i :class="['fa', 'fa-search']"></i> Consulte su tramite (SIGI)</a></li>
                                <li><a href="https://apps5.mineco.gob.pe/transparencia/Navegador/default.aspx" target="_blank"><i :class="['fa', 'fa-search']"></i> Consulta amigable (SIAF)</a></li>
                                <li><a href="https://gstramite.regionsanmartin.gob.pe/#/pages/login" target="_blank"><i :class="['fa', 'fa-search']"></i> GORESAM Suite Tramite</a></li>
                                <li><router-link to="/boletas_electronicas"><i :class="['fa', 'fa-search']"></i> Consulta Boletas Electrónicas</router-link></li>
                            </ul>
                        </div>

                        <div :class="['col-lg-2', 'col-md-6', 'footer-links']">
                            <h4>Enlaces directos</h4>
                            <ul :class="['fuente12']">
                                <li>
                                    <i :class="['bx', 'bx-chevron-right']"></i> 
                                    <router-link :to="'/nosotros'">
                                        INSTITUCIONAL
                                    </router-link>
                                </li>
                                <li>
                                    <i :class="['bx', 'bx-chevron-right']"></i> 
                                    <router-link :to="'/directorio'">
                                        DIRECTORIO
                                    </router-link>
                                </li>
                                <li>
                                    <i :class="['bx', 'bx-chevron-right']"></i> 
                                    <router-link :to="'/MesaParteVirtual'">
                                        MESA VIRTUAL
                                    </router-link>
                                </li>
                                <li>
                                    <i :class="['bx', 'bx-chevron-right']"></i> 
                                    <router-link :to="'/convocatorias'">
                                        CONVOCATORIAS

                                        <span v-if="notificacionConvocatoria>0" :class="['bell', 'badge', 'badge-light', 'pull-right', 'ml-2', 'colorRedBell', 'fuente10']" style="margin-top:-10px; width: 15px; height:15px;">
                                            {{notificacionConvocatoria}}
                                        </span>
                                    </router-link>
                                </li>
                                <li>
                                    <i :class="['bx', 'bx-chevron-right']"></i>
                                    <router-link :to="'/noticias?pag=1'">
                                        NOTICIAS

                                        <span v-if="notificacionNoticia>0" :class="['bell', 'badge', 'badge-light', 'pull-right', 'ml-2', 'colorRedBell', 'fuente10']" style="margin-top:-10px; width: 15px; height:15px;">
                                            {{notificacionNoticia}}
                                        </span>
                                    </router-link>
                                </li>
                                <!--
                                <li><i :class="['bx', 'bx-chevron-right']"></i> <router-link to="/fideicomiso">FIDEICOMISO</router-link></li>
                                -->
                                <li><i :class="['bx', 'bx-chevron-right']"></i> <router-link to="/organigrama">ORGANIGRAMA</router-link></li>
                                <li><i :class="['bx', 'bx-chevron-right']"></i> <router-link to="/acceso_informacion">ACCESO A LA INFORMACION</router-link></li>
                                <li><i :class="['bx', 'bx-chevron-right']"></i> <router-link to="/reporte_visitas">REPORTE VISITAS</router-link></li>
                                <li><i :class="['bx', 'bx-chevron-right']"></i> <a target="_blank" href="https://intranet.drasam.gob.pe/">INTRANET</a></li>
                            </ul>
                        </div>

                        <div :class="['col-lg-2', 'col-md-6', 'footer-newsletter']">
                            <h4>Redes sociales</h4>
                            <div :class="['social-links', 'mt-3']">
                                <a v-for="(val, key) in empresa.redes_sociales" :key="key" :href="val.url" :class="[val.descripcion]" target="_blank">
                                    <i :class="['fa', 'fa-1x', val.icono]" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div :class="['container']">
                <div :class="['copyright']">
                    &copy; Copyright <strong><span>{{empresa.abreviatura}}</span></strong>. Todos los derechos reservados
                </div>
                <div :class="['credits']">
                    Administrado por el &Aacute;rea de Tecnolog&iacute;as de la Informaci&oacute;n
                </div>
            </div>
        </footer>

        <a href="#" :class="['back-to-top']"><i :class="['icofont-simple-up']"></i></a>
    </div>
</template> 

<script>
    import {mapState, mapActions} from 'vuex';
    
    export default {
        name:'FootBar',
        data(){
           return{
               notificacionNoticia:0,
               notificacionConvocatoria:0,
           }
        },
        methods:{
            ...mapActions(['loadObjNotificacion']),
        },
        computed:{
            ...mapState(['empresa', 'redes_sociales', 'notificaciones']),
        },
        mounted(){
            this.loadObjNotificacion();
            this.notificacionNoticia = parseInt(this.notificaciones['NOTICIA']['cant']);
            this.notificacionConvocatoria = parseInt(this.notificaciones['CONVOCATORIA']['cant']);
        }
    }
</script>