<template>
    <div>
        <div id="loading"  v-if="loading">
            <div class="load-circle text-center" style=" ">
                <div style="text-align: center;">
                    <img src="/img/claro_negocios.png" class="img-fluid" style="text-align: center;">
                </div>

                <div style="text-align: center;"  class="pt-2 d-inline ">
                    <div role="status" class="spinner-grow text-danger spinner-grow-sm"  >
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div role="status" class="spinner-grow text-danger spinner-grow-sm"  >
                        <span class="sr-only">Loading...</span>
                    </div> 
                    <div role="status" class="spinner-grow text-danger spinner-grow-sm" >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <section id="topbar" :class="['d-none', 'd-lg-block', 'tolbarColorX']">
            <div :class="['container', 'clearfix']">
                <!--
                <div  v-for="(val, key) in empresa.sucursales" :key="key" >
                    <div :class="['contact-info', 'float-left', 'fuente11', 'colorWhite', 'mr-4']">
                        <i :class="['icofont-google-map']"></i> {{val.direccion}}
                    </div>
                </div>
                -->

                <div :class="['contact-info', 'float-left', 'fuente14', 'colorWhite', 'mr-4']">
                    <i :class="['icofont-institution']"></i> {{empresa.razon_social}} (R.U.C. {{empresa.ruc}})
                </div>

                <div :class="['contact-info', 'float-right', 'fuente14', 'colorWhite', 'mr-4']">
                    <i :class="['icofont-email']"></i> {{empresa.email}}
                </div>
            </div>
        </section>
        <!-- ======= Header ======= -->
        <header id="header">
            <div :class="['container']">
                <div :class="['logo', 'float-left']">      
                    <router-link v-if="empresa.logo" :to="'/'">
                        <img :src="empresa.path_file_logo" :alt="empresa.abreviatura" :class="['img-fluid', 'imgLogo']" />
                    </router-link>
                </div>

                <nav :class="['nav-menu', 'float-right', 'd-none', 'd-lg-block']">
                    <ul>
                        <li :class="[($route.name=='/')?'active':'']"><router-link :to="'/'"><i :class="['icofont-home', 'mr-1']"></i>Buscar comprobante</router-link></li>
                    </ul>
                </nav>
            </div>
        </header>
        <!-- End Header -->
    </div>
</template> 

<script>
    import {mapState} from 'vuex';

    export default {
        name:'ToolBar',
        computed:{
            ...mapState(['loading','empresa']),
        },
    }
</script>