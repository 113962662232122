<template>
    <Web/>
</template>
<script>
    import Web from '@/components/Web.vue';
    export default {
        name:'Dashboard',
        components:{
            Web
        }
    }
</script>