import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading:true,
    globales:{
      path_web:'',
      name_year:'',
    },
    empresa:{
      razon_social:"",
      abreviatura:"",
      ruc:"",
      telefonos:null,
      logo:null,
      pagina_web:null,
      path_file_logo:"",
      sucursales:[],
      redes_sociales:[],
    },
    redes_sociales:[],
  },
  mutations: {
    setLoading:(state,value)=>{
      Vue.set(state,'loading',value);
    },
    setEmpresa:(state,obj)=>{
      Vue.set(state,'empresa',obj);
      localStorage.setItem('empresaPropiedades', JSON.stringify(obj));
    },
  },
  actions: {
    loadObjEmpresa({commit, dispatch, getters}){
      let ObjEmpresa= localStorage.getItem('empresaPropiedades');
      
      if(ObjEmpresa){
        ObjEmpresa = JSON.parse(ObjEmpresa);
        commit('setEmpresa',ObjEmpresa);
      }
    },
  },
  modules: {
  }
})
