
import axios from 'axios';
export default{
    methods:{
        isNumber(e) {
            let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "0123456789";
            let specials = [8, 9, 37, 39, 46, 44];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },
        isLetter(e) {
            let key = e.keyCode || e.which;
            let keyString = String.fromCharCode(key).toLowerCase();
            let letters = "ABCDEFGHIJKLMÑNOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz";
            let specials = [32];
            let keySpecial = false;
            for (let i in specials) {
                if (key == specials[i]) {
                    keySpecial = true;
                    break;
                }
            }
            if (letters.indexOf(keyString) == -1 && !keySpecial) {
                e.preventDefault();
                return false;
            }
        },
        isEmpty(value){
            if(value==undefined || value==null || value==''){
                return true;
            }
            if (this.isObject(value)) {
                return !Object.keys(value).length
            }
            return false;
        },
        isObject(o) {
            return o instanceof Object && o.constructor === Object;
        },
        in_array(_string, _array) {
            return (_array.indexOf(_string) != -1);
        },
        capitalizarPrimeraLetra(cadena) {
            const primerCaracter = cadena.charAt(0).toUpperCase();
            const restoDeLaCadena = cadena.substring(1, cadena.length);
            return primerCaracter.concat(restoDeLaCadena);
        },
        downloadItem(file, label){
            axios({
                url: file,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', label);
                document.body.appendChild(link);
                link.click();
            });
        },
        dateFormat(value,format){
            if(format==undefined){
                format='DD/MM/YYYY';
            }
            return dayjs(value).format(format)
        },
    }
}